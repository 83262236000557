<template>
  <slot name="header"></slot>
  <div class="create-form">
    <div v-if="!props.edit">
      <label for="i_type">Project type<b v-if="!props.value.projectTypeId">*</b></label>
      <vue-select label="name" v-model="props.value.projectTypeId" :options="overviewStore.getProjectTypes"
        :appendToBody="true" :clearable="false" :reduce="(item) => item.id" />
    </div>
    <div v-if="$props.value?.projectTypeId == 0">
      <label for="i_invoiceType">Invoice type<b v-if="!props.value.invoiceTypeId">*</b></label>
      <vue-select label="name" :options="overviewStore.getInvoiceType" :clearable="false" :reduce="(item) => item.id"
        :appendToBody="true" v-model="props.value.invoiceTypeId" />
    </div>
    <div v-if="$props.value?.projectTypeId == 0">
      <label for="i_serviceId">Service<b v-if="!props.value.serviceId">*</b></label>
      <vue-select label="blServiceName" :options="settingsStore.getServices" :clearable="false" :appendToBody="true"
        :reduce="(item) => item.id" v-model="props.value.serviceId" />
    </div>
    <div>
      <label for="i_name">Project name<b v-if="!props.value.projectName">*</b></label>
      <input id="i_name" type="string" v-model="props.value.projectName" />
    </div>
    <div>
      <label for="i_start">Project start<b v-if="!props.value.projectStartPicker">*</b></label>
      <VueDatePicker :teleport="true" :enable-time-picker="false" month-picker
        v-model="props.value.projectStartPicker" />
    </div>
    <div>
      <label for="i_end">Project end<b v-if="!props.value.projectEndPicker">*</b></label>
      <VueDatePicker :teleport="true" :enable-time-picker="false" month-picker v-model="props.value.projectEndPicker" />
    </div>
    <div>
      <label for="i_number">Project number<b v-if="!props.value.projectNumber">*</b></label>
      <input id="i_number" type="string" v-model="props.value.projectNumber" />
    </div>
    <div>
      <label for="i_person">Responsible person<b v-if="!props.value.responsiblePerson">*</b></label>
      <input id="i_person" type="string" v-model="props.value.responsiblePerson" />
    </div>
    <div>
      <label for="i_manager">Project manager<b v-if="!props.value.projectManager">*</b></label>
      <input id="i_manager" type="string" v-model="props.value.projectManager" />
    </div>
    <div v-if="$props.value?.projectTypeId == 2">
      <label>Use checkbox for day activity report</label>
      <input type="checkbox" v-model="$props.value.isCheckbox">
    </div>
    <div v-if="$props.value?.projectTypeId == 2">
      <label>Use custom activity</label>
      <input type="checkbox" v-model="$props.value.isCustomActivity">
    </div>
    <div>
      <label for="i_description">Description</label>
      <textarea id="i_description" v-model="props.value.description" />
    </div>
  </div>
  <slot name="buttons"></slot>
</template>

<script setup lang="ts">

import { ProjectItem } from "@/services/entities/ProjectItem";
import { useOverviewStore } from "@/stores/overview";
import { onMounted, reactive } from "vue";
import VueSelect from "vue-select";
import { useSettingsStore } from "@/stores/setting";

const emit = defineEmits(["close"]);
const overviewStore = useOverviewStore();
const settingsStore = useSettingsStore();

const props = defineProps({
  title: String,
  value: { Type: {} as ProjectItem, default: {} as ProjectItem },
  edit: { Type: Boolean, default: false },
});

onMounted(async () => {
  await settingsStore.loadServices();
});
</script>


<style scoped lang="scss">
label b {
  color: var(--status-error);
  margin-left: 0.1rem;
}

.create-form>div {
  max-width: 750px;
  min-width: 360px;
  padding-bottom: 5px;

  input {
    width: 240px;
  }

  .v-select {
    width: 250px;
  }
}
</style>