<template>
    <div>
        <div class="header">
            <span class="title">Project detail</span>
            <DxButton icon="trash" @click="emit('delete', props.project.id)" />
        </div>
        <div class="p-detail">
            <div>
                <span>Project type</span>
                <span>{{ overviewStore.getProjectTypes.find(a => a.id == props.project.projectTypeId)?.name }}</span>
            </div>
            <div v-if="props.project?.projectTypeId == 0">
                <span>Invoice type</span>
                <span>{{ overviewStore.getInvoiceType.find(a => a.id == props.project.invoiceTypeId)?.name }}</span>
            </div>
            <div v-if="props.project?.projectTypeId == 0">
                <span>Service</span>
                <span>{{ props.project?.service?.blServiceName }}</span>
            </div>
            <div v-if="props.project?.projectTypeId == 2">
                <span>Use checkbox</span>
                <span>{{ (props.project?.isCheckbox ? "Yes" : "No") }}</span>
            </div>
            <div v-if="props.project?.projectTypeId == 2">
                <span>Is custom activity</span>
                <span>{{ (props.project?.isCustomActivity ? "Yes" : "No") }}</span>
            </div>
            <div>
                <span>Project name</span>
                <span>{{ props.project.projectName }}</span>
            </div>
            <div>
                <span>Project start</span>
                <span>{{ formatDateToMonthYear(props.project.projectStart) }}</span>
            </div>
            <div>
                <span>Project end</span>
                <span>{{ formatDateToMonthYear(props.project.projectEnd) }}</span>
            </div>
            <div>
                <span>Project number</span>
                <span>{{ props.project.projectNumber }}</span>
            </div>
            <div>
                <span>Responsible person</span>
                <span>{{ props.project.responsiblePerson }}</span>
            </div>
            <div>
                <span>Project manager</span>
                <span>{{ props.project.projectManager }}</span>
            </div>
            <div>
                <span>Description</span>
                <span>{{ props.project.description }}</span>
            </div>
        </div>
        <div class="buttons">
            <button @click="emit('edit', props.project.id)" class="accent">Edit</button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ProjectItem } from "@/services/entities/ProjectItem";
import { useOverviewStore } from "@/stores/overview";
import DxButton from 'devextreme-vue/button';

const overviewStore = useOverviewStore();
const emit = defineEmits(['edit', 'delete']);
const props = defineProps({
    project: { Type: {} as ProjectItem, default: {} as ProjectItem }
});

function formatDateToMonthYear(input: Date): string;
function formatDateToMonthYear(input: string): string | null;
function formatDateToMonthYear(input: Date | string): string | null {
  let dateObject: Date;

  if (input instanceof Date) {
    dateObject = input;
  } else {
    // Try to parse the input string as a date
    const parsedDate = new Date(input);
    
    // Check if the parsed date is valid
    if (isNaN(parsedDate.getTime())) {
      // Return null for an invalid date string
      return null;
    }

    dateObject = parsedDate;
  }

  const month = dateObject.toLocaleString('default', { month: 'long' });
  const year = dateObject.getFullYear();
  const monthYearString = `${month} ${year}`;

  return monthYearString;
}

</script>

<style scoped lang="scss">
.title {
    font-size: 16px;
    margin-left: 0;
}

.header {
    margin-left: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.p-detail {
    display: flex;
    flex-direction: column;

    &>div {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #BABABA;
        padding: 10px 0px 5px 0px;

        &:first-child {
            border-top: 1px solid #BABABA;
        }

        span {
            width: 50%;
            overflow: hidden;

            &:nth-child(2n) {
                font-size: 13px;
            }
        }
    }
}
</style>