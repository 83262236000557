<template>
  <DxDataGrid :hover-state-enabled="true" :show-borders="false" :data-source="props.projects" id="project-table"
    :selected-row-keys="selectedKeys" :auto-navigate-to-focused-row="true" key-expr="id">
    <DxFilterRow :visible="true" />
    <DxHeaderFilter :visible="true" />
    <DxStateStoring :enabled="true" type="localStorage" storage-key="project-table" />
    <DxColumn data-field="projectName" data-type="string" caption="Project name" />
    <DxColumn data-field="projectNumber" data-type="string" caption="Project number" />
    <DxColumn data-field="service.blServiceName" data-type="string" caption="Service" />
    <DxColumn data-field="projectType" data-type="string" caption="Project type" />
    <DxColumn data-field="isActive" data-type="boolean" caption="Active" />
    <DxColumn type="buttons" caption="Actions" :width="70">
      <!-- <DxButton hint="Hide detail" icon="eyeclose" styling-mode="contained" type="normal" @click="clearDetail" /> -->
      <DxButton hint="View detail" icon="eyeopen" styling-mode="contained" type="normal" @click="showDetail" />
    </DxColumn>
    <DxPaging :page-size="10" />
    <DxPager :show-page-size-selector="true" :allowed-page-sizes="[5, 10, 20]" />
  </DxDataGrid>
</template>

<script setup lang="ts">
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxPaging, DxPager,
  DxHeaderFilter,
  DxStateStoring,
  DxButton,
} from "devextreme-vue/data-grid";
import { onMounted, ref } from "vue";
import { ProjectItem } from "@/services/entities/ProjectItem";

const emit = defineEmits(["detail"]);

const selectedKeys = ref([] as number[]);

const props = defineProps({
  projects: { Type: [] as ProjectItem[], default: [] as ProjectItem[] },
});
function showDetail(e: any) {
  selectedKeys.value = [e.row.data.id];
  emit("detail", e.row.data.id);
}

</script>

<style scoped lang="scss"></style>